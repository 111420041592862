import { createApp } from "vue";
import { createRouter, createWebHistory, RouterView } from "vue-router";
import CheckoutPage from "./pages/CheckoutPage.vue";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { createI18n } from "vue-i18n";
const container = document.getElementById("checkout");
const routes = [
  {
    path: "/courses/:course_id/orders/:order_uid/checkout",
    component: CheckoutPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const numberFormats = {
  en: {
    currency: {
      style: "currency",
      currency: "NOK",
      notation: "standard",
      currencyDisplay: "symbol",
    },
    roundedCurrency: {
      style: "currency",
      currency: "NOK",
      notation: "standard",
      currencyDisplay: "symbol",
      maximumFractionDigits: 0,
    },

    decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: "percent",
      useGrouping: false,
    },
  },
  nb: {
    currency: {
      style: "currency",
      currency: "NOK",
      suffix: " kr",
      useGrouping: true,
      currencyDisplay: false,
    },
    decimal: {
      style: "decimal",
      minimumSignificantDigits: 3,
      maximumSignificantDigits: 5,
    },
    percent: {
      style: "percent",
      useGrouping: false,
    },
  },
  uk: {
    currency: {
      style: "currency",
      currency: "NOK",
      useGrouping: true,
      currencyDisplay: "code",
    },
    decimal: {
      style: "decimal",
      minimumSignificantDigits: 3,
      maximumSignificantDigits: 5,
    },
    percent: {
      style: "percent",
      useGrouping: false,
    },
  },
  es: {
    currency: {
      style: "currency",
      currency: "NOK",
      useGrouping: true,
      currencyDisplay: "symbol",
    },
    decimal: {
      style: "decimal",
      minimumSignificantDigits: 3,
      maximumSignificantDigits: 5,
    },
    percent: {
      style: "percent",
      useGrouping: false,
    },
  },
  "en-US": {},
};

const i18n = createI18n({
  legacy: false,
  locale: gon.locale,
  numberFormats,
});

const mountApp = (el: HTMLElement) => {
  const app = createApp(RouterView);
  Sentry.init({
    app,
    dsn: "https://dd22ef1425da4424abb9189c1b81c6d9@o503631.ingest.sentry.io/6133825",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ["lingu.test", "lingu.no", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
  });

  app.use(router);
  app.use(i18n);
  return app.mount(el);
};
if (container) mountApp(container);
