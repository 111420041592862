<template>
  <checkout-view
    v-if="course"
    :course="course"
    :step="step"
    :isLoading="submitting"
  >
    <template #header>
      <template v-if="step === 1 || step === 3">
        <a
          v-if="course"
          :href="course.url"
          class="flex items-center text-gray-400 hover:text-gray-600 transition-colors transition-200"
        >
          <left-arrow-icon />
          <span class="pl-2.5">{{ t("back") }}</span>
        </a>
      </template>
      <template v-else>
        <a
          @click.prevent="step = 1"
          href="#"
          class="flex items-center text-gray-400 hover:text-gray-600 transition-colors transition-200"
        >
          <left-arrow-icon />
          <span class="pl-2.5">{{ t("back") }}</span>
        </a>
      </template>
    </template>
    <template #left>
      <template v-if="step === 1">
        <div class="flex-1">
          <div class="pb-8">
            <h1 class="text-3xl sm:text-5xl font-extrabold text-custom-text">
              {{ t("booking") }}
            </h1>
          </div>
          <div class="pt-5">
            <h3 class="text-xl font-bold mb-4 text-custom-grey-1">
              {{ t("participant_information") }}
            </h3>
            <form @submit.prevent="submitProfile" v-if="user_profile">
              <div
                class="pt-4 lg:flex justify-between space-y-4 lg:space-y-0 lg:space-x-3"
              >
                <label class="block w-full">
                  <span
                    class="flex items-end justify-between text-custom-text pb-1 text-sm font-semibold"
                  >
                    {{ t("first_name") }}
                  </span>
                  <input
                    type="text"
                    v-model="user_profile.first_name"
                    name="first-name"
                    placeholder=""
                    class="block py-2 px-3 border border-gray-100 bg-gray-100 rounded-md placeholder-gray-500 focus:border-gray-500 transition outline-none w-full shadow-sm min-h-12 focus:bg-white"
                  />
                </label>
                <label class="block w-full">
                  <span
                    class="flex items-end justify-between text-custom-text pb-1 text-sm font-semibold"
                  >
                    {{ t("last_name") }}
                  </span>
                  <input
                    type="text"
                    v-model="user_profile.last_name"
                    name="last-name"
                    placeholder=""
                    class="block py-2 px-3 border border-gray-100 bg-gray-100 rounded-md placeholder-gray-500 focus:border-gray-500 transition outline-none w-full shadow-sm min-h-12 focus:bg-white"
                  />
                </label>
              </div>
              <div class="pt-4">
                <label class="block w-full">
                  <span
                    class="flex items-end justify-between text-custom-text pb-1 text-sm font-semibold"
                  >
                    {{ t("email") }}
                  </span>
                  <input
                    type="email"
                    v-model="user_profile.email"
                    name="email"
                    placeholder=""
                    disabled
                    class="block py-2 px-3 border border-gray-100 bg-gray-100 rounded-md placeholder-gray-500 focus:border-gray-500 transition outline-none w-full shadow-sm min-h-12 focus:bg-white"
                    :class="{
                      'border-custom-red': false,
                    }"
                  />
                </label>
                <p
                  v-if="false"
                  class="flex items-center text-custom-red text-xs font-medium mt-1"
                >
                  <svg class="svg-icon svg-attention mr-1.5">
                    <use
                      xlink:href="#attention"
                      width="100%"
                      height="100%"
                    ></use>
                  </svg>
                  Email address is invalid
                </p>
              </div>
              <div class="pt-4">
                <label class="block w-full">
                  <span
                    class="flex items-end justify-between text-custom-text pb-1 text-sm font-semibold"
                  >
                    {{ t("mobile_number") }}
                  </span>
                  <input
                    type="text"
                    v-model="user_profile.telephone_number"
                    name="tel"
                    class="block py-2 px-3 border border-gray-100 bg-gray-100 rounded-md placeholder-gray-500 focus:border-gray-500 transition outline-none w-full shadow-sm min-h-12 focus:bg-white"
                  />
                </label>
              </div>
              <div
                class="fixed bottom-0 left-0 right-0 px-4 py-2 shadow-custom-shadow bg-white sm:static sm:shadow-none sm:p-0"
              >
                <div class="sm:pt-10">
                  <checkout-action :disabled="submitting">
                    <span class="leading-none"> {{ t("next") }} </span>
                    <svg class="svg-icon svg-chevron-right">
                      <use
                        xlink:href="#chevron-right"
                        width="100%"
                        height="100%"
                      ></use>
                    </svg>
                  </checkout-action>
                </div>
              </div>
            </form>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="flex-1">
          <div>
            <div class="pb-8">
              <h1 class="text-3xl sm:text-5xl font-extrabold text-custom-text">
                {{ t("payment") }}
              </h1>
              <!--            <p class="sm:text-lg font-medium mt-4 text-custom-grey-1">-->
              <!--              Fast-track Norwegian also includes all activities from the Simply-->
              <!--              online program-->
              <!--            </p>-->
            </div>
            <div class="pt-5 pb-5 border-t border-custom-grey-7">
              <div
                v-if="subscriptionCourseSetting"
                class="flex justify-between"
              >
                <h3 class="text-xl font-bold text-custon-grey-1">
                  {{ t("monthly_billing") }}
                </h3>
                <label class="ln-switcher relative">
                  <input
                    type="checkbox"
                    class="absolute opacity-0 pointer-events-none"
                    role="switch"
                    v-model="monthlyBilling"
                  />
                  <span
                    class="relative inline-flex flex-shrink-0 h-7 w-14 rounded-full cursor-pointer transition duration-100 bg-custom-grey-6 text-primary"
                  >
                    <i
                      class="pointer-events-none inline-block h-7 w-7 rounded-full bg-white shadow transform ring-0 transition ease-linear duration-200"
                    ></i>
                  </span>
                </label>
              </div>
            </div>
            <checkout-course-settings
              v-if="!monthlyBilling"
              v-model="order.booking_option_id"
              :course_settings="availableCourseSettings"
              :base_price="subscriptionCourseSetting?.amount ?? null"
            />
            <div>
              <form
                class="divide-y divide-custom-grey-7"
                @submit.prevent.stop="payWithCard"
              >
                <div
                  v-if="!monthlyBilling"
                  class="pt-5 pb-5 border-t border-custom-grey-7"
                >
                  <form>
                    <ul class="ln-radioTabs flex bg-gray-100 rounded-lg p-1">
                      <li class="flex-1">
                        <label class="flex-1 block h-full relative">
                          <input
                            type="radio"
                            v-model="checkoutState.paymentGateway"
                            name="type-payment"
                            value="stripe"
                            class="absolute opacity-0 pointer-events-none"
                          />
                          <span
                            class="block rounded-md lg:text-lg min-h-11 p-2 sm:p-4 cursor-pointer hover:text-gray-700 text-gray-500 transition-all duration-200 h-full text-center"
                          >
                            <span
                              class="flex flex-col items-center justify-end h-full"
                            >
                              <img
                                :src="creditCardIcon"
                                width="100"
                                class="flex-1 object-contain object-center"
                                alt=""
                              />
                              <span class="mt-1 sm:mt-2 text-center">
                                {{ t("credit_card") }}
                              </span>
                            </span>
                          </span>
                        </label>
                      </li>
                      <li class="flex-1">
                        <label class="flex-1 block h-full relative">
                          <input
                            type="radio"
                            v-model="checkoutState.paymentGateway"
                            name="type-payment"
                            value="manual"
                            class="absolute opacity-0 pointer-events-none"
                          />
                          <span
                            class="block rounded-md lg:text-lg min-h-11 p-2 sm:p-4 cursor-pointer hover:text-gray-700 text-gray-500 transition-all duration-200 h-full text-center"
                          >
                            <span
                              class="flex flex-col items-center justify-end h-full"
                            >
                              <img
                                :src="invoiceIcon"
                                width="40"
                                class="flex-1 object-contain object-center"
                                alt=""
                              />
                              <span class="mt-1 sm:mt-2 text-center">
                                {{ t("invoice") }}
                              </span>
                            </span>
                          </span>
                        </label>
                      </li>
                    </ul>
                  </form>
                </div>
                <div
                  v-if="cards && checkoutState.paymentGateway === 'stripe'"
                  class="py-5"
                >
                  <h3 class="text-xl font-bold mb-4 text-custon-grey-1">
                    {{ t("pay_with_credit_card") }}
                  </h3>
                  <label class="block w-full" v-if="cards.length > 0">
                    <span class="text-custom-text pb-1 text-sm font-semibold">
                    </span>
                    <select
                      class="block py-2 pl-3 border border-gray-100 rounded-md cursor-pointer placeholder-gray-500 transition outline-none w-full shadow-sm min-h-12 text-custom-text bg-gray-100 focus:bg-white focus:border-gray-500"
                      v-model="selectedCardToken"
                    >
                      <option
                        v-for="card in cards"
                        :value="card.id"
                        :key="card.id"
                      >
                        &bull;&bull;&bull;&bull; {{ card.last4 }}
                        {{ card.brand }} {{ card.exp_month }}/{{
                          card.exp_year
                        }}
                      </option>
                      <option :value="null">
                        {{ t("add_new_card") }}
                      </option>
                    </select>
                  </label>
                  <div class="py-5" v-if="selectedCardToken === null">
                    <div class="space-y-3">
                      <checkout-stripe-card
                        :stripe="stripe"
                        ref="stripeCard"
                      ></checkout-stripe-card>
                    </div>
                  </div>
                </div>
                <div
                  v-if="checkoutState.paymentGateway === 'manual'"
                  class="py-5 border-b border-custom-grey-7"
                >
                  <h3 class="text-xl font-bold mb-4 text-custon-grey-1">
                    {{ t("pay_with_invoice") }}
                  </h3>
                </div>

                <checkout-address
                  :address="addressData"
                  :addressList="availableAddresses"
                  :addressType="addressType"
                  :order="order"
                  @update:address="setAddressData"
                  @update:order="setOrderData"
                />
                <div>
                  <checkout-error
                    v-if="checkoutState.paymentError"
                    :message="checkoutState.paymentError"
                  />
                  <div class="pt-5 pb-5">
                    <div>
                      <div class="pt-4 sm:pt-7">
                        <label
                          class="inline-flex items-center js-checkbox ln-checkbox cursor-pointer text-primary"
                        >
                          <input
                            type="checkbox"
                            :value="true"
                            v-model="agreeToTerms"
                            hidden=""
                            class="absolute h-0 w-0 -left-full pointer-events-none"
                          />
                          <span>
                            <i18n-t
                              keypath="agree_terms_label"
                              tag="span"
                              class="text-custom-grey-1"
                            >
                              <template v-slot:link>
                                <a
                                  href="/terms-and-conditions"
                                  target="_blank"
                                  class="text-primary hover:text-primary-700"
                                  >{{ t("terms_and_conditions") }}</a
                                >
                              </template>
                            </i18n-t>
                          </span>
                        </label>
                      </div>
                      <checkout-action :disabled="!agreeToTerms">
                        <i18n-t
                          v-if="checkoutState.paymentGateway === 'stripe'"
                          tag="span"
                          keypath="pay_with_credit_card"
                          class="leading-none"
                        />
                        <i18n-t
                          v-else-if="checkoutState.paymentGateway === 'manual'"
                          tag="span"
                          keypath="send_invoice"
                          class="leading-none"
                        />
                        <i18n-t
                          v-else
                          tag="span"
                          keypath="pay"
                          class="leading-none"
                        />
                        <svg class="svg-icon svg-chevron-right">
                          <use
                            xlink:href="#chevron-right"
                            width="100%"
                            height="100%"
                          ></use>
                        </svg>
                      </checkout-action>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </template>
    </template>
    <template #right>
      <checkout-course-info
        :course="course"
        :course_setting="selectedCourseSetting"
        :groups="groups"
        :base_price="subscriptionCourseSetting?.amount || null"
        v-model:selectedGroupID="order.group_id"
        v-model:startDate="order.start_date"
      />
      <checkout-coupon :coupon="order.coupon" @update:coupon_code="(code) => order.coupon_code = code" />
    </template>
  </checkout-view>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, ref, unref, watch } from "vue";
import {
  getCourse,
  getCourseOrder,
  getCourseGroups,
  getCurrentProfile,
  updateCurrentProfile,
  updateCourseOrder,
  createAddress,
  updateAddress,
  getCardsList,
  createCard,
  getPaymentGateways,
  submitOrder,
} from "@/scripts/application/api";
import invoiceIcon from "../assets/invoice-icon.png";
import creditCardIcon from "../assets/credits-icon.png";
import { useRoute } from "vue-router";
import { loadStripe } from "@stripe/stripe-js";
import { useI18n } from "vue-i18n";
import { CheckoutView } from "@/scripts/application/views";
import {
  LeftArrowIcon,
  CheckoutCourseInfo,
  CheckoutStripeCard,
  CheckoutAddress,
  CheckoutCourseSettings,
  CheckoutError,
  CheckoutAction,
  CheckoutCoupon,
} from "@/scripts/application/components";
import type {
  IAddress,
  ICard,
  ICourseSetting,
  IOrder,
  IPaymentGateway,
  IUserProfile,
} from "@/scripts/application/types";
import type { Stripe } from "@stripe/stripe-js";
import type { AxiosError } from "axios";
import axios from "axios";
import {course, order, groups} from "@/scripts/application/stores/checkout";

type tCheckoutState = {
  step: number;
  loaded: boolean;
  paymentGateway: "stripe" | "manual";
  paymentError: string | null;
};
const { t } = useI18n();
const checkoutState = reactive<tCheckoutState>({
  step: 1,
  loaded: false,
  paymentError: null,
  paymentGateway: "stripe",
});
const route = useRoute();
const stripe = ref<Stripe | null>();
const step = ref(1);
const submitting = ref<boolean>(false);
const user_profile = ref<IUserProfile>();

const stripeCard = ref<InstanceType<typeof CheckoutStripeCard>>();
const emptyAddress = {
  id: undefined,
  company: null,
  contact_person: null,
  organization_id: null,
  state: null,
  address1: "",
  address2: null,
  city: "",
  country: "",
  postal_code: null,
  billing_email: null,
  billing_ref: null,
};
const addressData = reactive<IAddress>({ ...emptyAddress });
const agreeToTerms = ref<boolean>(false);
const cards = ref<ICard[]>();
const paymentGateways = ref<IPaymentGateway[]>();

function setAddressData(address: Partial<IAddress> | null) {
  if (address) Object.assign(addressData, address);
  else Object.assign(addressData, emptyAddress);
}

function setOrderData(orderData: Partial<IOrder>) {
  Object.assign(order, orderData);
}

getPaymentGateways(
  route.params.course_id as string,
  route.params.order_uid as string
).then((payment_gateways) => (paymentGateways.value = payment_gateways));

const selectedCourseSetting = computed<ICourseSetting | null>(() => {
  if (!course.value) return null;

  return (
    course.value?.course_settings.find(
      (cs) => cs.id === order.booking_option_id
    ) ?? null
  );
});
const monthlyBilling = computed<boolean>({
  get() {
    return selectedCourseSetting.value?.kind === "subscription" ?? false;
  },
  set(value) {
    if (value) {
      if (selectedCourseSetting.value?.kind !== "subscription") {
        order.booking_option_id = subscriptionCourseSetting.value?.id;
      }
    } else {
      order.booking_option_id = course.value?.course_settings.find(
        (cs) => cs.kind !== "subscription"
      )?.id;
    }
  },
});
const availableCourseSettings = computed(() => {
  if (!course.value) return [];
  if (monthlyBilling.value)
    return course.value.course_settings.filter(
      (cs) => cs.kind === "subscription"
    );
  else if (course.value.variety === "personal")
    return course.value.course_settings
      .filter((cs) => cs.kind === "private_group")
      .sort((a, b) => a.amount - b.amount);
  else
    return course.value.course_settings
      .filter((cs) => cs.kind === "licence")
      .sort((a, b) => a.amount - b.amount);
});
const subscriptionCourseSetting = computed(() => {
  if (!course.value) return null;
  return course.value.course_settings.find((cs) => cs.kind === "subscription");
});
const availableAddresses = computed(() => {
  if (!user_profile.value) return [];
  if (
    checkoutState.paymentGateway === "stripe" ||
    order.invoice_type === "individual"
  )
    return user_profile.value.addresses.filter((a) => !a.billing_email);
  else return user_profile.value.addresses.filter((a) => a.billing_email);
});
// const activeAddress = computed<IAddress | null>({
//   get() {
//     return (
//       availableAddresses.value.find((a) => a.id === order.address_id) || null
//     );
//   },
//   set(value) {
//     order.address_id = value?.id || null;
//   },
// });
const addressType = computed<"basic" | "individual" | "company">(() => {
  if (checkoutState.paymentGateway === "stripe") return "basic";
  if (order.invoice_type === "individual") return "individual";
  return "company";
});

const selectedCardToken = computed<string | null | undefined>({
  get() {
    if (order.card_token !== undefined && order.card_token !== null)
      return order.card_token;
    if (!cards.value) return null;
    // initialize with existing card
    if (order.card_token === undefined && cards.value.length > 0)
      return cards.value[0].id;

    return order.card_token ?? null;
  },
  set(value) {
    if (value === order.card_token) return;

    console.log(`${order.card_token} => ${value}`);
    order.card_token = value;
  },
});

// watch(activeAddress, (value) => {
//   if (value) Object.assign(addressData, value);
//   else Object.assign(addressData, emptyAddress);
// });
function updateOrder(newData: Partial<IOrder>) {
  if (!checkoutState.loaded) return;
  updateCourseOrder(
      route.params.course_id as string,
      route.params.order_uid as string,
      newData
  ).then((remoteOrder) => {
    Object.assign(order, remoteOrder);
  });
}

watch(
  () => order.group_id,
  (newData, oldData) => {
    if (newData === oldData) return;
    if (!checkoutState.loaded) return;
    console.log(`${oldData} => ${newData}`);
    updateOrder({ group_id: newData });
  }
);
watch(
  () => order.address_id,
  (newData, oldData) => {
    if (newData === oldData) return;
    if (!checkoutState.loaded) return;
    console.log(`${oldData} => ${newData}`);
    updateOrder({ address_id: newData });
  }
);

watch(
    () => order.coupon_code,
    (newData, oldData) => {
      if (newData === oldData) return;
      if (!checkoutState.loaded) return;
      console.log(`${oldData} => ${newData}`);
      updateOrder({ coupon_code: newData });
    }
);

watch(
  () => order.booking_option_id,
  (newData, oldData) => {
    if (newData === oldData) return;
    console.log(`${oldData} => ${newData}`);
    if (!checkoutState.loaded) return;
    updateOrder({ booking_option_id: newData });
  }
);
watch(
  () => order.start_date,
  (newData, oldData) => {
    if (newData === oldData) return;
    if (!checkoutState.loaded) return;
    console.log(`${oldData} => ${newData}`);
    updateOrder({ start_date: newData });
  }
);
watch(subscriptionCourseSetting, (value) => {
  if (!value) monthlyBilling.value = false;
});
watch(monthlyBilling, (value) => {
  if (value) checkoutState.paymentGateway = "stripe";
});
watch(step, (newData) => {
  localStorage.setItem("checkout-state", newData.toString());
});

onMounted(() => {
  try {
    const checkoutState = localStorage.getItem("checkout-state");
    if (checkoutState) {
      step.value = parseInt(checkoutState);
    }
  } catch (_err) {
    //
  }
});

function submitProfile() {
  if (!user_profile.value) return;
  submitting.value = true;
  updateCurrentProfile(user_profile.value).then((remoteProfile) => {
    step.value = 2;
    user_profile.value = remoteProfile;
    submitting.value = false;
  });
}

async function payWithCard() {
  checkoutState.paymentError = null;
  if (!agreeToTerms.value) return;
  if (!user_profile.value) return;
  if (submitting.value) return;
  submitting.value = true;
  if (!selectedCardToken.value && checkoutState.paymentGateway === "stripe") {
    const result = await stripeCard.value?.getToken();
    try {
      const newCard = await createCard(result.token.id);
      cards.value = [...(cards.value || []), newCard];
      selectedCardToken.value = newCard.id;
    } catch (err) {
      console.log(err);
      submitting.value = false;
      if (axios.isAxiosError(err) && err.response?.status === 422) {
        const error = err as AxiosError<{
          error: string;
        }>;
        checkoutState.paymentError = error.response?.data?.error || null;
      }
      return;
    }
    getCardsList().then((cardsList) => {
      cards.value = cardsList;
    });
  }

  const addressParams = unref(addressData);
  if (addressParams.id) {
    try {
      await updateAddress(addressParams);
    } catch (e) {
      submitting.value = false;
      checkoutState.paymentError = "Error updating address";
      return;
    }
  } else {
    try {
      const address = await createAddress(addressParams);
      order.address_id = address.id;
      user_profile.value = {
        ...user_profile.value,
        addresses: [...user_profile.value.addresses, address],
      };
    } catch (e) {
      submitting.value = false;
      checkoutState.paymentError = "Error creating address";
      return
    }
  }

  const result = await submitOrder(
    route.params.course_id as string,
    route.params.order_uid as string,
    {
      card_token: selectedCardToken.value,
      payment_gateway: checkoutState.paymentGateway,
    }
  );
  console.log(`${result.type}:`);
  if (result.type === "success") {
    // submitting.value = false;
    location.href = result.redirect_path;
    return;
  }
  if (result.type === "requires_action" || result.type === "requires_confirmation") {
    console.log("Stripe confirmCardPayment");
    console.log(result.result);
    stripe.value
      ?.confirmCardPayment(result.result.stripe_payment_intent.client_secret)
      .then((resp) => {
        if (resp.error) {
          checkoutState.paymentError = resp.error.message ?? null;
        } else {
          location.href = result.redirect_path;
        }
        submitting.value = false;
      });
    return;
  }
  if (result.type === "requires_payment_method") {
    console.log(result.result);

    submitting.value = false;
    return;
  }
  if (result.type === "general_failure") {
    checkoutState.paymentError = result.result.message;
    submitting.value = false;
    return;
  }
  // debugger;
  submitting.value = false;
  // .catch((err) => {
  //   submitting.value = false;
  //   if (axios.isAxiosError(err) && err.response?.status === 422) {
  //     const error = err as AxiosError<{
  //       error: string;
  //     }>;
  //     checkoutState.paymentError = error.response?.data?.error || null;
  //   }
  // });
  // submitting.value = false;
}

// eslint-disable-next-line no-undef
loadStripe(gon.stripe_key).then((s) => {
  stripe.value = s;
});

Promise.all([
  getCourse(route.params.course_id as string),
  getCurrentProfile(),
  getCourseOrder(
    route.params.course_id as string,
    route.params.order_uid as string
  ),
  getCourseGroups(route.params.course_id as string),
  getCardsList(),
])
  .then(
    ([remoteCourse, remoteProfile, remoteOrder, remoteGroups, cardsList]) => {
      course.value = remoteCourse;
      user_profile.value = remoteProfile;
      groups.value = remoteGroups;
      Object.assign(order, remoteOrder);
      cards.value = cardsList;
    }
  )
  .then(() => {
    checkoutState.loaded = true;
  });
</script>

<i18n lang="yaml">
en:
  booking: "Booking"
  back: "Back"
  first_name: "First name"
  last_name: "Last name"
  participant_information: Fill in the details of the course participant
  email: "Email"
  mobile_number: Mobile number
  next: "Next"
  payment: Payment
  monthly_billing: Monthly billing
  pay_with_credit_card: Pay with credit card
  address: Address
  postal_code: Postal code
  city: City
  country: Country
  agree_terms_label: I agree to the {link}.
  terms_and_conditions: Terms and conditions
  credit_card: Credit card
  invoice: Invoice
  pay_with_invoice: Pay via invoice (bank transfer)
  company_name: Company name
  contact_person: Contact person
  send_invoice: Send invoice
  add_new_card: Add new credit card
nb:
  booking: "Påmelding"
  back: "Tilbake"
  first_name: "Fornavn"
  last_name: "Etternavn"
  participant_information: Informasjon om kursdeltaker
  email: "E-post"
  mobile_number: Mobilnummer
  next: "Neste"
  payment: Betaling
  monthly_billing: Månedlig betaling
  pay_with_credit_card: Betal med kredittkort
  address: Adresse
  postal_code: Postnummer
  city: By
  country: Land
  agree_terms_label: Jeg godtar {link}.
  terms_and_conditions: Vilkår og betingelser
  credit_card: Kredittkort
  invoice: Faktura
  pay_with_invoice: Betal via faktura (bankoverføring)
  company_name: Bedriftsnavn
  contact_person: Kontaktperson
  send_invoice: Send faktura
  add_new_card: Legg til nytt kredittkort
uk:
  booking: "Бронювання"
  back: "Назад"
  first_name: "Ім'я"
  last_name: "Прізвище"
  participant_information: Заповніть дані про учасника курсу
  email: "Електронна пошта"
  mobile_number: Мобільний номер
  next: "Далі"
  payment: Оплата
  monthly_billing: Місячний розрахунок
  pay_with_credit_card: Оплатити кредитною карткою
  address: Адреса
  postal_code: Поштовий індекс
  city: Місто
  country: Країна
  agree_terms_label: Я погоджуюсь з {link}.
  terms_and_conditions: Умовами та положеннями
  credit_card: Кредитна картка
  invoice: Рахунок
  pay_with_invoice: Оплатити рахунком (банківський переказ)
  company_name: Назва компанії
  contact_person: Контактна особа
  send_invoice: Надіслати рахунок
  add_new_card: Додати нову кредитну картку
es:
  booking: "Inscripción"
  back: "Volver"
  first_name: "Nombre"
  last_name: "Apellido"
  participant_information: Rellene los datos del participante del curso
  email: "Correo electrónico"
  mobile_number: Número de teléfono móvil
  next: "Siguiente"
  payment: Pago
  monthly_billing: Facturación mensual
  pay_with_credit_card: Pagar con tarjeta de crédito
  address: Dirección
  postal_code: Código postal
  city: Ciudad
  country: País
  agree_terms_label: Estoy de acuerdo con los {link}.
  terms_and_conditions: Términos y condiciones
  credit_card: Tarjeta de crédito
  invoice: Factura
  pay_with_invoice: Pagar por factura (transferencia bancaria)
  company_name: Nombre de la empresa
  contact_person: Persona de contacto
  send_invoice: Enviar factura
  add_new_card: Agregar nueva tarjeta de crédito
</i18n>
