<template>
  <div class="mt-6">
    <div
      class="p-4 border border-custom-red rounded-2xl text-custom-red bg-custom-red bg-opacity-5"
    >
      <div class="flex">
        <svg class="svg-icon svg-attention mr-1.5 mt-0.5">
          <use xlink:href="#attention" width="100%" height="100%"></use>
        </svg>
        <div>
          <p v-if="header" class="font-bold text-lg">{{ header }}</p>
          <p v-else class="font-bold text-lg">
            <slot name="header">Payment error</slot>
          </p>
          <p v-if="message" class="text-custom-text">{{ message }}</p>
          <p v-else class="text-custom-text">
            <slot name="message" />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "checkout-error",
  props: {
    message: {
      type: String,
      required: false,
    },
    header: {
      type: String,
      required: false,
      default: "Payment error",
    },
  },
};
</script>
