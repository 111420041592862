<template>
  <div class="pt-5 pb-5">
    <ul
      class="ln-radioTabs flex bg-gray-100 rounded-lg"
      v-if="addressType !== 'basic'"
    >
      <li class="flex-1">
        <label class="flex-1 block h-full relative">
          <input
            type="radio"
            v-model="invoice_type"
            name="type-payment"
            value="individual"
            class="absolute opacity-0 pointer-events-none"
          />
          <span
            class="block rounded-md lg:text-lg min-h-11 p-2 sm:p-4 cursor-pointer hover:text-gray-700 text-gray-500 transition-all duration-200 h-full text-center"
          >
            <span class="flex flex-col items-center justify-end h-full">
              <i18n-t tag="span" keypath="individual" />
            </span>
          </span>
        </label>
      </li>
      <li class="flex-1">
        <label class="flex-1 block h-full relative">
          <input
            type="radio"
            v-model="invoice_type"
            name="type-payment"
            value="company"
            class="absolute opacity-0 pointer-events-none"
          />
          <span
            class="block rounded-md lg:text-lg min-h-11 p-2 sm:p-4 cursor-pointer hover:text-gray-700 text-gray-500 transition-all duration-200 h-full text-center"
          >
            <span class="flex flex-col items-center justify-end h-full">
              <i18n-t tag="span" keypath="company" />
            </span>
          </span>
        </label>
      </li>
    </ul>

    <div class="pt-2 pb-4">
      <label class="block w-full py-5" v-if="addressList.length > 0">
        <span class="text-custom-text pb-1 text-sm font-semibold"> </span>
        <select
          class="block py-2 pl-3 border border-gray-100 rounded-md cursor-pointer placeholder-gray-500 transition outline-none w-full shadow-sm min-h-12 text-custom-text bg-gray-100 focus:bg-white focus:border-gray-500"
          v-model="activeAddress"
        >
          <option
            v-for="address in addressList"
            :value="address"
            :key="address.id"
          >
            {{ address.address1 }}, {{ address.city }},
            {{ address.country }}
          </option>
          <option :value="null">
            {{ t("add_new_address") }}
          </option>
        </select>
      </label>
    </div>
    <template v-if="addressType === 'company'">
      <div class="pt-2 pb-4">
        <checkout-company-address
          v-if="!order.address_id"
          @company:selected="transformCompanyData"
        />
        <div class="pt-2 pb-4" v-else>
          <label for="company-name" class="block w-full">
            <i18n-t
              tag="span"
              keypath="company_name"
              class="flex items-end justify-between text-custom-text pb-1 text-sm font-semibold"
            />
          </label>
          <span class="text-lg font-semibold">{{ address.company }}</span>
        </div>
      </div>

      <div class="pt-2 pb-4">
        <label class="block w-full">
          <i18n-t
            tag="span"
            keypath="organization_id"
            class="flex items-end justify-between text-custom-text pb-1 text-sm font-semibold"
          />
          <input
            type="text"
            name="organization-id"
            v-model.number="organization_id"
            class="block py-2 px-3 border border-gray-100 bg-gray-100 rounded-md placeholder-gray-500 focus:border-gray-500 transition outline-none w-full shadow-sm min-h-12 focus:bg-white"
          />
        </label>
        <p class="flex items-center text-custom-grey-3 pt-1.5 text-sm">
          <svg class="svg-icon svg-attention mr-1.5">
            <use xlink:href="#attention" width="100%" height="100%"></use>
          </svg>
          <i18n-t tag="span" keypath="organization_id_info" />
        </p>
      </div>
    </template>
    <div class="pt-2 pb-4">
      <label class="block w-full">
        <i18n-t
          tag="span"
          keypath="address"
          class="flex items-end justify-between text-custom-text pb-1 text-sm font-semibold"
        />
        <input
          type="text"
          name="address1"
          v-model="address1"
          placeholder=""
          class="block py-2 px-3 border border-gray-100 bg-gray-100 rounded-md placeholder-gray-500 focus:border-gray-500 transition outline-none w-full shadow-sm min-h-12 focus:bg-white"
        />
      </label>
    </div>
    <div
      class="pt-2 pb-4 lg:flex justify-between space-y-4 lg:space-y-0 lg:space-x-3"
    >
      <label class="block w-full">
        <i18n-t
          class="flex items-end justify-between text-custom-text pb-1 text-sm font-semibold"
          tag="span"
          keypath="postal_code"
        />
        <input
          type="text"
          name="postal-code"
          v-model="postal_code"
          class="block py-2 px-3 border border-gray-100 bg-gray-100 rounded-md placeholder-gray-500 focus:border-gray-500 transition outline-none w-full shadow-sm min-h-12 focus:bg-white"
        />
      </label>
      <label class="block w-full">
        <i18n-t
          class="flex items-end justify-between text-custom-text pb-1 text-sm font-semibold"
          tag="span"
          keypath="city"
        />
        <input
          type="text"
          name="city"
          v-model="city"
          class="block py-2 px-3 border border-gray-100 bg-gray-100 rounded-md placeholder-gray-500 focus:border-gray-500 transition outline-none w-full shadow-sm min-h-12 focus:bg-white"
        />
      </label>
      <label class="block w-full">
        <i18n-t
          tag="span"
          class="text-custom-text pb-1 text-sm font-semibold"
          keypath="country"
        />
        <select
          name="country"
          v-model="country"
          class="block py-2 pl-3 border border-gray-100 rounded-md cursor-pointer placeholder-gray-500 transition outline-none w-full shadow-sm min-h-12 text-custom-text bg-gray-100 focus:bg-white focus:border-gray-500"
        >
          <option
            v-for="countryItem in countries"
            :key="countryItem.code"
            :value="countryItem.code"
          >
            {{ countryItem.name }}
          </option>
        </select>
      </label>
    </div>

    <div class="pt-2 pb-4 border-t" v-if="addressType === 'company'">
      <div
        class="md:flex justify-between space-y-4 md:space-y-0 md:space-x-3 pt-2 pb-2"
      >
        <label class="block w-full">
          <i18n-t
            class="flex items-end justify-between text-custom-text pb-1 text-sm font-semibold"
            tag="span"
            keypath="contact_person"
          />
          <input
            type="text"
            name="person-name"
            v-model.trim="contact_person"
            class="block py-2 px-3 border border-gray-100 bg-gray-100 rounded-md placeholder-gray-500 focus:border-gray-500 transition outline-none w-full shadow-sm min-h-12 focus:bg-white"
          />
        </label>
        <label class="block w-full">
          <span
            class="flex items-end justify-between text-custom-text pb-1 text-sm font-semibold"
          >
            <i18n-t tag="span" keypath="billing_ref" />
            <span class="text-custom-grey-3 font-normal">Optional</span>
          </span>
          <input
            type="text"
            name="reference"
            placeholder=""
            v-model.trim="billing_ref"
            class="block py-2 px-3 border border-gray-100 bg-gray-100 rounded-md placeholder-gray-500 focus:border-gray-500 transition outline-none w-full shadow-sm min-h-12 focus:bg-white"
          />
        </label>
      </div>
      <div class="pt-2 pb-4">
        <label class="block w-full">
          <i18n-t
            tag="span"
            keypath="billing_email"
            class="flex items-end justify-between text-custom-text pb-1 text-sm font-semibold"
          />
          <input
            type="text"
            name="billing_email"
            v-model.trim="billing_email"
            class="block py-2 px-3 border border-gray-100 bg-gray-100 rounded-md placeholder-gray-500 focus:border-gray-500 transition outline-none w-full shadow-sm min-h-12 focus:bg-white"
          />
        </label>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import CheckoutCompanyAddress from "./CheckoutCompanyAddress.vue";
import type {
  IAddress,
  IBrregCompanyInfo,
  ICountry,
  IOrder,
} from "@/scripts/application/types";
import { getCountriesList } from "@/scripts/application/api";

export default defineComponent({
  name: "CheckoutAddress",
  setup() {
    const { t } = useI18n();
    return { t };
  },
  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    "update:address": (data: Partial<IAddress> | null) => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    "update:order": (data: Partial<IOrder>) => true,
  },
  components: {
    CheckoutCompanyAddress,
  },
  data: () => ({
    countries: [] as ICountry[],
  }),
  props: {
    address: {
      type: Object as () => IAddress,
      default: () => ({}),
    },
    addressList: {
      type: Array as () => IAddress[],
      default: () => [],
    },
    order: {
      type: Object as () => Partial<IOrder>,
      required: true,
    },
    addressType: {
      type: String as () => "basic" | "individual" | "company",
      default: "basic",
    },
  },
  computed: {
    activeAddress: {
      get(): IAddress | null {
        return (
          this.addressList.find((a) => a.id === this.order.address_id) || null
        );
      },
      set(value: IAddress) {
        this.$emit("update:order", { address_id: value?.id || null });
      },
    },
    invoice_type: {
      get(): "company" | "individual" | undefined {
        return this.order.invoice_type;
      },
      set(value: "individual" | "company") {
        this.$emit("update:order", { invoice_type: value });
      },
    },
    organization_id: {
      get() {
        return this.address?.organization_id || null;
      },
      set(value: number | null) {
        this.$emit("update:address", { organization_id: value || null });
      },
    },
    address1: {
      get(): string {
        return this.address?.address1 ?? "";
      },
      set(value: string) {
        this.$emit("update:address", { address1: value });
      },
    },
    city: {
      get(): string {
        return this.address?.city ?? "";
      },
      set(value: string) {
        this.$emit("update:address", { city: value });
      },
    },
    postal_code: {
      get(): string {
        return this.address?.postal_code ?? "";
      },
      set(value: string) {
        this.$emit("update:address", { postal_code: value });
      },
    },
    country: {
      get(): string {
        return this.address?.country ?? "";
      },
      set(value: string) {
        this.$emit("update:address", { country: value });
      },
    },
    contact_person: {
      get(): string | null {
        return this.address?.contact_person ?? null;
      },
      set(value: string) {
        this.$emit("update:address", { contact_person: value });
      },
    },
    billing_email: {
      get(): string | null {
        return this.address?.billing_email ?? null;
      },
      set(value: string) {
        this.$emit("update:address", { billing_email: value });
      },
    },
    billing_ref: {
      get(): string | null {
        return this.address?.billing_ref ?? null;
      },
      set(value: string) {
        this.$emit("update:address", { billing_ref: value });
      },
    },
  },
  mounted() {
    getCountriesList().then((countries) => {
      this.countries = countries;
    });
  },
  watch: {
    activeAddress: {
      immediate: true,
      handler(value: IAddress | null) {
        if (value) {
          this.$emit("update:address", value);
        } else {
          this.$emit("update:address", null);
        }
      },
    },
    addressList: {
      immediate: true,
      handler(value: IAddress[]) {
        if (value.length === 0) {
          this.$emit("update:address", null);
        }
      },
    },
  },
  methods: {
    transformCompanyData(company: IBrregCompanyInfo) {
      this.$emit("update:address", {
        company: company.name,
        organization_id: parseInt(company.organizationId),
        address1: company.address,
        postal_code: company.postalCode,
        city: company.city,
        country: "NO",
      });
    },
  },
});
</script>

<i18n lang="yaml">
en:
  first_name: "First name"
  last_name: "Last name"
  email: "Email"
  mobile_number: Mobile number
  address: Street address
  postal_code: Postal code
  city: City
  country: Country
  company_name: Company name
  contact_person: Contact person
  organization_id: Organization no.
  billing_email: Billing email address
  billing_ref: PO / Reference
  individual: Individual
  company: Company
  add_new_address: Add new address
  organization_id_info: Enter a Norwegian business registration number, 9 digits
nb:
  first_name: "Fornavn"
  last_name: "Etternavn"
  email: "E-post"
  mobile_number: Mobilnummer
  address: Gateadresse
  postal_code: Postnummer
  city: By
  country: Land
  company_name: Bedriftsnavn
  contact_person: Kontaktperson
  organization_id: Organisasjonsnummer
  billing_email: Faktura e-postadresse
  billing_ref: PO / Referanse
  individual: Privatperson
  company: Bedrift
  add_new_address: Legg til ny adresse
  organization_id_info: Oppgi et norsk organisasjonsnummer, 9 siffer
uk:
  first_name: "Ім'я"
  last_name: "Прізвище"
  email: "Електронна пошта"
  mobile_number: Мобільний номер
  address: Вулиця
  postal_code: Поштовий індекс
  city: Місто
  country: Країна
  company_name: Назва компанії
  contact_person: Контактна особа
  organization_id: Ідентифікатор організації
  billing_email: Електронна адреса для рахунків
  individual: Фізична особа
  company: Компанія
  add_new_address: Додати нову адресу
  organization_id_info: Введіть номер бізнес-реєстрації в Норвегії, 9 цифр
es:
  first_name: "Nombre"
  last_name: "Apellido"
  email: "Correo electrónico"
  mobile_number: Número de teléfono móvil
  address: Dirección
  postal_code: Código postal
  city: Ciudad
  country: País
  company_name: Nombre de la empresa
  contact_person: Persona de contacto
  organization_id: Identificación de la organización
  billing_email: Dirección de correo electrónico de facturación
  individual: Persona física
  company: Empresa
  add_new_address: Agregar nueva dirección
  organization_id_info: Ingrese un número de registro de negocios noruego, 9 dígitos
</i18n>
