<i18n>
en:
  do_it: Do it!
uk:
  do_it: Зроби це!
nb:
  do_it: Gjør det!
es:
  do_it: ¡Hazlo!
</i18n>
<template>
  <div
    class="fixed bottom-0 left-0 right-0 px-4 py-2 shadow-custom-shadow bg-white sm:static sm:shadow-none sm:p-0"
  >
    <div class="sm:pt-5">
      <button
        :disabled="disabled"
        type="submit"
        class="transition inline-flex items-center px-3 py-2 md:px-6 justify-center border font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 shadow-sm text-base min-h-11 w-full sm:w-72 h-10 sm:h-14 bg-primary text-white disabled:bg-gray-400 hover:bg-primary-700"
      >
        <slot>
          <i18n-t tag="span" keypath="do_it" class="leading-none" />
          <svg class="svg-icon svg-chevron-right">
            <use xlink:href="#chevron-right" width="100%" height="100%"></use>
          </svg>
        </slot>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { Translation } from "vue-i18n";
export default {
  components: {
    "i18n-t": Translation,
  },
  name: "CheckoutAction",
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>
