<template>
  <div
    class="rounded-3xl border border-color-custom-grey-7 p-4 sm:p-8 lg:p-10 divide-y divide-custom-grey-7"
  >
    <div class="pb-6">
      <p
        class="float-left flex rounded-xl justify-center items-center h-14 w-14 bg-gray-100 text-primary mr-4"
      >
        <img
          class="rounded-xl"
          alt=""
          :src="props.course.common_featured_image_url"
        />
      </p>
      <h3 class="text-xl font-bold text-custom-grey-1 mb-1">
        {{ props.course.course_title }}
      </h3>
      <p>
        <span class="text-xl font-bold mb-4 text-custom-grey-1">
          {{ n(props.course_setting.amount, "currency") }}
        </span>
        <span v-if="monthlyBilling" class="text-sm text-custom-grey-3">
          / month</span
        >
      </p>
    </div>
    <div class="py-6">
      <form action="#">
        <div v-if="props.groups.length > 0">
          <label class="block w-full">
            <span class="text-custom-text pb-1 text-sm font-semibold">
              Lesson schedule (Group ID)
            </span>
            <select
              name="schedule"
              v-model="selectedGroup"
              class="block py-2 pl-3 border border-gray-100 rounded-md cursor-pointer placeholder-gray-500 transition outline-none w-full shadow-sm min-h-12 text-custom-text bg-gray-100 focus:bg-white focus:border-gray-500"
            >
              <!--                :value="props.selectedGroupID"-->
              <!--                @change="updateGroupID"-->
              <option
                v-for="group in props.groups"
                :value="group"
                :key="group.id"
              >
                {{ group.group_title }}
              </option>
            </select>
          </label>
        </div>
        <div class="py-4">
          <label class="block w-full">
            <span class="text-custom-text pb-1 text-sm font-semibold">
              Start date (Stripe subscription plan starts this date)
            </span>
            <date-picker
              v-model="startDate"
              :timePicker="false"
              :autoApply="true"
              :month-change-on-scroll="false"
              :locale="$i18n.locale"
              format="dd/MM/yyyy"
              :minDate="new Date()"
              :ignoreTimeValidation="true"
              :time-picker="false"
              :enable-time-picker="false"
              :clearable="false"
            />
          </label>
        </div>
        <!-- TODO: Group schedule -->
        <!--          <div class="pt-4">-->
        <!--            <p-->
        <!--              class="flex items-center text-custom-grey-3 pb-3 text-sm sm:text-normal"-->
        <!--            >-->
        <!--              <svg class="svg-icon svg-attention mr-1.5">-->
        <!--                <use xlink:href="#attention" width="100%" height="100%"></use>-->
        <!--              </svg>-->
        <!--              Group started 21 May 2021-->
        <!--            </p>-->
        <!--            <p-->
        <!--              class="flex items-center text-custom-grey-3 text-sm sm:text-normal"-->
        <!--            >-->
        <!--              <svg class="svg-icon svg-attention mr-1.5">-->
        <!--                <use xlink:href="#attention" width="100%" height="100%"></use>-->
        <!--              </svg>-->
        <!--              For Spanish speakers only-->
        <!--            </p>-->
        <!--          </div>-->
      </form>
    </div>
    <div
      class="py-6"
      v-if="selectedGroup && selectedGroup.instructors.length > 0"
    >
      <h3 class="text-sm font-semibold text-custom-text pb-1">
        Program instructors
      </h3>
      <div
        v-for="instructor in selectedGroup.instructors"
        :key="instructor.id"
        class="flex items-center rounded-xl bg-custom-grey-8 py-2 px-3"
      >
        <p class="flex items-center">
          <img
            :src="instructor.avatar_url"
            alt=""
            class="h-11 w-11 rounded-full object-cover mr-2"
          />
          <span class="text-custom-grey-1">{{ instructor.full_name }}</span>
        </p>
      </div>
    </div>
    <div class="pt-7">
      <div
        v-if="monthlyBilling"
        class="flex items-center justify-between font-medium text-custom-grey-3 sm:text-lg pb-1"
      >
        <p class="whitespace-nowrap">
          {{ n(course_setting.amount, "currency") }}
        </p>
      </div>
      <div
        v-else-if="props.base_price"
        class="flex items-center justify-between font-medium text-custom-grey-3 sm:text-lg pb-1"
      >
        <p>
          {{ n(props.base_price, "currency") }} ×
          {{ course_setting.duration }} months
        </p>
        <p class="whitespace-nowrap">
          {{ n(props.base_price * course_setting.duration, "currency") }}
        </p>
      </div>
      <div
        v-if="!monthlyBilling && baseDiscount"
        class="flex items-center justify-between font-medium text-custom-grey-3 sm:text-lg pb-3"
      >
        <p>{{ course_setting.duration }} months booking discount</p>
        <p class="text-primary whitespace-nowrap">
          {{ n(baseDiscount, "currency") }}
        </p>
      </div>
      <div
        v-if="order.coupon"
        class="relative flex items-center justify-between font-medium text-custom-grey-3 sm:text-lg pb-3"
      >
        <p>{{ order.coupon.code }} coupon discount</p>
        <p class="text-primary whitespace-nowrap">
          {{ n(-order.discount_amount, "currency") }}
        </p>
      </div>
      <div class="flex items-center justify-between">
        <p class="font-bold text-custom-text text-lg">Total to pay</p>
        <p class="text-xl sm:text-2xl font-bold whitespace-nowrap">
          {{ n(order.final_amount, "currency") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {useI18n} from "vue-i18n";
import {computed} from "vue";
import type {ICourse, ICourseSetting, IGroup,} from "@/scripts/application/types";
import DatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import {order, orderLoading} from "@/scripts/application/stores/checkout";
import CheckoutLoader from "@/scripts/application/components/CheckoutLoader.vue";

const { n } = useI18n();
// const date = ref(new Date());

const props = defineProps<{
  course: ICourse;
  course_setting: ICourseSetting;
  selectedGroupID: number | null;
  startDate: Date | string;
  groups: IGroup[];
  base_price: number | null;
}>();

// const selectedGroup = computed(() => {
//   return props.groups.find((group) => group.id === props.selectedGroupID);
// });

const monthlyBilling = computed<boolean>(() => {
  return props.course_setting.kind === "subscription";
});

const emit = defineEmits<{
  (event: "update:selectedGroupID", groupID: number | null): void;
  (event: "update:startDate", startDate: Date | string): void;
}>();

const selectedGroup = computed({
  set(group: IGroup | null) {
    emit("update:selectedGroupID", group?.id ?? null);
  },
  get(): IGroup | null {
    return (
      props.groups.find((group) => group.id === props.selectedGroupID) || null
    );
  },
});

const baseDiscount = computed(() => {
  if(!props.base_price) return 0.0;
  return props.course_setting.amount - props.base_price! * props.course_setting.duration
});

const startDate = computed({
  set(date: Date | string) {
    console.log(date);
    if (date instanceof Date) {
      return emit(
        "update:startDate",
        `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      );
    }
    emit("update:startDate", date);
  },
  get(): Date | string {
    if (typeof props.startDate === "string") {
      return new Date(props.startDate);
    }
    if (typeof props.startDate === "object") {
      return props.startDate;
    }
    return new Date();
  },
});
//
// function updateGroupID(e) {
//   debugger
//   emit('update:selectedGroupID', e.target.value)
// }
</script>
