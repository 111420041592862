<template>
  <div>
    <div class="pb-5">
      <ul class="space-y-1 select-none">
        <li
          v-for="course_setting in courseSettingsWithDiscount"
          class="ln-radiobutton"
          :key="course_setting.id"
        >
          <label class="block relative">
            <input
              type="radio"
              name="course-setting-option"
              :value="course_setting.id"
              v-model="selectedCourseSettingID"
              class="absolute pointer-events-none opacity-0"
            />
            <!--              :checked="course_setting.id === modelValue"-->
            <!--              @change="emit('update:modelValue', course_setting.id)"-->
            <span
              class="flex flex-wrap items-center cursor-pointer rounded-md text-primary px-2 py-3 sm:px-3 sm:py-3.5 overflow-hidden"
            >
              <i
                class="block flex-none h-4 w-4 sm:h-5 sm:w-5 rounded-full bg-white mr-2 sm:mr-1"
              ></i>
              <span class="w-auto font-bold text-custom-grey-1">{{
                course_setting.name
              }}</span>
              <span class="ml-auto text-custom-grey-1">
                {{ n(course_setting.amount, "currency") }}
              </span>
              <span
                v-if="course_setting.discount"
                class="text-right sm:text-left sm:w-auto ml-1 sm:ml-3"
                >{{
                  n(course_setting.discount, "currency", {
                    maximumFractionDigits: 0,
                  })
                }}</span
              >
            </span>
          </label>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ICourseSetting } from "@/scripts/application/types";
import { useI18n } from "vue-i18n";
import { computed } from "vue";
const props = defineProps<{
  course_settings: Array<ICourseSetting>;
  base_price: number | null;
  modelValue: number | null;
}>();
const emit = defineEmits({
  "update:modelValue": (value: number | null) => true,
});

const selectedCourseSettingID = computed<number | null>({
  get() {
    return props.modelValue || null;
  },
  set(value) {
    emit("update:modelValue", value ?? null);
  },
});

const courseSettingsWithDiscount = computed<ICourseSetting[]>(() => {
  if (!props.base_price) return props.course_settings;

  return props.course_settings.map((course_setting) => {
    return {
      ...course_setting,
      discount:
        course_setting.amount - props.base_price! * course_setting.duration,
    };
  });
});
const { n } = useI18n();
</script>

<style scoped></style>
