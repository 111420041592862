<template>
  <div class="pt-7">
    <div class="text-center">
      <a v-if="!enterDiscount && !coupon" href="#" class="text-primary cursor-pointer transition-colors hover:text-primary-700"
         @click.prevent="enterDiscount = true">Enter discount code</a>
      <div v-if="enterDiscount || coupon">
        <div class="flex justify-center">
          <div class="relative w-96">
            <input type="text"
                   v-model="couponCode"
                   name="discount"
                   placeholder="Coupon code"
                   class="block uppercase placeholder:normal-case py-2 px-3 border border-gray-100 bg-gray-100 rounded-md placeholder-gray-500 focus:border-gray-500 transition outline-none w-full shadow-sm min-h-12 focus:bg-white h-14 pr-40">
            <button type="button"
                    @click.prevent="applyCoupon"
                    class="transition inline-flex items-center px-3 py-2 md:px-6 justify-center border font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 shadow-sm text-base min-h-11 absolute top-1 right-1 w-36 h-12 bg-primary text-white hover:bg-primary-700">
                            <span class="leading-none">
                              Apply code
                            </span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <p class="flex items-center justify-center pt-4 pb-1">
      <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="text-primary"
      >
        <path
            d="M11.9882 21.7541C12.2822 21.9111 12.6512 21.9101 12.9452 21.7531C20.9571 17.4491 21.5261 9.0401 21.5191 6.6491C21.5182 6.2551 21.2842 5.9051 20.9232 5.7461L12.8732 2.1801C12.6142 2.0661 12.3192 2.0661 12.0602 2.1811L4.06615 5.7471C3.71115 5.9061 3.47915 6.2501 3.47415 6.6391C3.44015 9.0181 3.91915 17.4451 11.9882 21.7541ZM9.17415 10.2931L11.4672 12.5861L15.7602 8.2931L17.1742 9.7071L11.4672 15.4141L7.76015 11.7071L9.17415 10.2931Z"
            fill="currentColor"
        ></path>
      </svg>
      <span class="text-sm sm:text-normal font-bold text-custom-grey-2"
      >14 days satisfaction guarantee.</span
      >
    </p>
    <p class="text-sm sm:text-normal text-custom-grey-2 text-center">
      Your course is fully refundable 14 days after booking date.
    </p>
  </div>
</template>
<script lang="ts" setup>
import {ref} from "vue";
import {useRoute} from "vue-router";
import {tryCoupon} from "@/scripts/application/api";
import type {ICoupon, IOrder} from "@/scripts/application/types";
const enterDiscount = ref(false);
const props = defineProps<{
  coupon: ICoupon
}>();
const route = useRoute();
const couponCode = ref(props.coupon ? props.coupon.code : "");
const emit = defineEmits<{
  (event: "update:coupon_code", coupon_code: string | null): void;
}>();
async function applyCoupon() {
  try {
    const coupon = await tryCoupon(
        route.params.course_id as string,
        route.params.order_uid as string,
        couponCode.value
    );
    console.log(coupon);
    emit('update:coupon_code', coupon.code);
  }catch(e) {
    emit('update:coupon_code', null);
  }
}
</script>
